import lang from "./lang.vue";
import {
  getMenuList, //获取菜单
} from "@/api/dataSet/menuSet";
import { logout } from "@/api/login";
import {
  treeDataTranslate, //放置成树型结构
  getRole,
} from "@/utils/common.js";
export default {
  name: "Header",
  components: {
    lang,
  },
  data() {
    return {
      menuDialogVisible: false,
      proDia: false, //产品弹窗
      routName: "", //当前路由名
      openeds: [],
      activeClass: "",
      scollTop: 0,
      nowMenu: {}, //当前激活菜单
      menuChildList: [], //子菜单数据
      menuList: [], //主菜单数据
      userInfo: null,
      userAdmin: false,
      isBlackLogo: false,
      showEnglish: false, //语言切换显示
    };
  },
  mounted() {
    // 加载初始数据
    this.dispathStoreLoaing();
  },
  created() {
    // 获取角色判断权限
    this.getRolePer();

    // 监听内容部分滚动条
    let _that = this;
    document.addEventListener("scroll", (e) => {
      _that.scollTop = document.documentElement.scrollTop;
    });
    // 监听菜单栏的鼠标移除事件
    let triggers = document.querySelectorAll(".navbar-nav>li.nav-item");
    triggers.forEach((el) => {
      el.addEventListener("mouseenter", function () {
        this.classList.add("open");
      });
      el.addEventListener("mouseleave", function () {
        this.classList.remove("open");
      });
    });
    setTimeout(() => {
      // 留资界面需要加导航栏阴影
      if (this.$store.state.pageset.pcshow) {
        let headerVis = document.querySelectorAll(".headerVis")[0];
        if (this.routName === "reservation") {
          headerVis.classList.add("orderHeaderLinear");
        } else {
          headerVis.classList.remove("orderHeaderLinear");
        }
      }
    }, 100);

    // 设置菜单栏的字体颜色(第一页为白色)
    document.documentElement.style.setProperty("--var-primary-color", "#fff");
  },
  watch: {
    // 监听滚动条乡下滚动的时候显示出订购条
    scollTop(val) {
      if (this.$store.state.pageset.pcshow) {
        let headerVis = document.querySelectorAll(".headerVis")[0];
        this.scollTop = val;
        const rt = this.$route.matched[0].name;
        //滚动下滑改header
        if (val > 300 && rt === "proScoll") {
          // 超出三百，导航栏显示阴影
          headerVis.classList.add("orderHeaderVis");
        } else {
          headerVis.classList.remove("orderHeaderVis");
        }
      }
    },
    $route: function () {
      this.dispathStore();
    },
    "$route.name": {
      handler(newVal) {
        this.routName = newVal;
        // 获取角色判断权限
        this.getRolePer();
        this.activeClass =
          this.routName != "Home" ? "header-no-home-active" : "";
        let headerVis = document.querySelectorAll(".headerVis")[0];
        // if (this.routName === 'proChemical') {
        //     document.documentElement.style.setProperty('--var-primary-color', '#171A20');
        // }
        // 如果为留资和加入我们界面，导航栏有渐变阴影样式
        if (this.routName === "reservation") {
          headerVis.classList.add("orderHeaderLinear");
        } else {
          headerVis.classList.remove("orderHeaderLinear");
          document.documentElement.style.setProperty(
            "--var-primary-color",
            "#fff"
          );
        }
      },
    },
  },
  methods: {
    // 获取角色判断权限
    getRolePer() {
      this.routName = this.$route.name;
      let blackPage = ["register", "about", "news"];
      if (blackPage.indexOf(this.routName) > -1) {
        this.isBlackLogo = true;
      } else {
        this.isBlackLogo = false;
      }
      this.userInfo = this.$store.state.user.userInfo;
      this.userAdmin = getRole();
    },
    //获取菜单
    async getMenuList() {
      this.tableMenuLoading = true;
      let langeuage = this.$store.getters.zh ? 0 : 1; //语言(0:汉语;1:英语)
      let res = await getMenuList(langeuage);
      if (res.code === 200) {
        this.menuList = treeDataTranslate(res.data, "pageId");
        // 加载完成后，监听菜单的鼠标移除
        let inputName = document.getElementsByClassName("menu-dia")[0];
        if (inputName) {
          inputName.addEventListener("mouseleave", (event) => {
            setTimeout(() => {
              this.$store.dispatch("pageset/getMenuShow", false);
              //如果当前轮播是第一条
              if (this.$store.state.pageset.bannerIndex === 0) {
                document.documentElement.style.setProperty(
                  "--var-primary-color",
                  "#fff"
                );
              } else {
                document.documentElement.style.setProperty(
                  "--var-primary-color",
                  "#171A20"
                );
              }
            }, 300);
          });
        }
      }
    },
    // 鼠标移入显示抽屉
    async handleOpen(type, menuItem) {
      let _that = this;
      if (type === "enter") {
        this.nowMenu = menuItem;
        // 当前菜单的子集
        this.menuChildList = menuItem.children;
        setTimeout(() => {
          _that.$store.dispatch("pageset/getMenuShow", true);
          document.documentElement.style.setProperty(
            "--var-primary-color",
            "#171A20"
          );
        }, 300);
      } else {
        setTimeout(() => {
          _that.$store.dispatch("pageset/getMenuShow", false);
        }, 300);
      }
    },
    // 打开链接
    solutiondetails(menu) {
      console.log(menu);
      // 存在下级菜单不打开页面
      if (menu.children && menu.path != "/solution") {
        return;
      } else {
        this.$router.push({
          path: this.$store.getters.zh ? menu.path : "/en" + menu.path,
          query: {
            pageId: menu.pageId,
          },
        });
      }
      // if (this.nowMenu.path === '/solution') {
      //     this.$router.push({
      //         path: this.$store.getters.zh ? menu.path : '/en' + menu.path
      //     });
      // }
      // else if (menu.path != '/' && menu.path != '') {
      //     console.log( menu.pageId);
      //     // 折叠所有展开的菜单
      //     this.$router.push({
      //         path: this.$store.getters.zh ? menu.path : '/en' + menu.path,
      //         query: {
      //             pageId: menu.pageId
      //         }
      //     });
      // }

      // 折叠所有展开的菜单
      this.openeds = [];
      this.proDia = false;
      this.menuDialogVisible = false;
    },

    // 打开下级数据
    openProduct(item) {
      this.nowMenu = item;
      if (item.children) {
        this.menuChildList = item.children;
        this.proDia = true;
      } else {
        // 如果是一级菜单,不打开下层遮罩
        this.$router.push({
          path: this.$store.getters.zh ? item.path : "/en" + item.path,
          query: {
            pageId: item.pageId,
          },
        });
        this.menuDialogVisible = false;
      }
    },

    retention() {
      if (this.$store.getters.zh) this.$router.push("/reservation");
      else this.$router.push("/en/reservation");
    },
    // 登录
    openLogin() {
      if (this.$store.getters.zh) this.$router.push("/login");
      else this.$router.push("/en/login");
      this.menuDialogVisible = false;
    },
    // 退出
    async logout() {
      let res = await logout();
      if (res.code) {
        this.$store.dispatch("user/logout").then(() => {
          this.$router.push(`/login?redirect=${this.$route.fullPath}`);
        });
        this.changeSet("close");
        this.$router.push("/login");
      }
    },
    openDataSet() {
      let routeUrl = this.$router.resolve({
        path: "/materialManagement",
      });
      window.open(routeUrl.href, "_blank");
    },

    changeSet(type) {
      if (type === "open") {
        this.$store.dispatch("pageset/getIsSetting", true);
      } else {
        this.$store.dispatch("pageset/getIsSetting", false);
      }
    },

    //  加载初始语言数据
    dispathStoreLoaing() {
      let patharry = this.$route.fullPath.split("/");
      try {
        // 免费接口容易崩
        fetch("https://ip.useragentinfo.com/json")
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
            if (data.country === "中国" && patharry[1] != "en") {
              this.$store.dispatch("pageset/getzh", true);
              this.$store.dispatch("pageset/getchangetitle", "中文");
              this.$store.dispatch("pageset/getchange", "zh");
              this.$router.push("/");
              this.showEnglish = false;
            } else {
              //  如果是英文
              this.$store.dispatch("pageset/getzh", false);
              this.$store.dispatch("pageset/getchangetitle", "English");
              this.$store.dispatch("pageset/getchange", "en");
              this.$router.push("/en");
              this.showEnglish = true;
            }
            // 获取菜单
            this.getMenuList();
          });
      } catch (error) {
        const lang = navigator.language.slice(0, 2); // 如"zh"/"en"
        if (lang === "zh" && patharry[1] != "en") {
          this.$store.dispatch("pageset/getzh", true);
          this.$store.dispatch("pageset/getchangetitle", "中文");
          this.$store.dispatch("pageset/getchange", "zh");
          this.$router.push("/");
          this.showEnglish = false;
        } else {
          //  如果是英文
          this.$store.dispatch("pageset/getzh", false);
          this.$store.dispatch("pageset/getchangetitle", "English");
          this.$store.dispatch("pageset/getchange", "en");
          this.$router.push("/en");
          this.showEnglish = true;
        }
        // 获取菜单
        this.getMenuList();
      }
    },

    dispathStore() {
      let patharry = this.$route.fullPath.split("/");
      if (patharry[1] === "en") {
        //  如果是英文
        this.$store.dispatch("pageset/getzh", false);
        this.$store.dispatch("pageset/getchangetitle", "English");
        this.$store.dispatch("pageset/getchange", "en");
      } else {
        this.$store.dispatch("pageset/getzh", true);
        this.$store.dispatch("pageset/getchangetitle", "中文");
        this.$store.dispatch("pageset/getchange", "zh");
      }
      // 获取菜单
      this.getMenuList();
    },

    openReservation() {
      if (this.$store.getters.zh) this.$router.push("/reservation");
      else this.$router.push("/en/reservation");
    },
    closeEnglishMobile() {
      // this.showEnglish = false;
    },
  },
};
