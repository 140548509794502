
import Swiper from "swiper";
import "swiper/dist/css/swiper.css";
import Footer from "@/components/footer/footer.vue";
import FooterEN from "@/components/footer/en/footer_en.vue";
export default {
  components: {
    Swiper,
    Footer,
    FooterEN
  },
  data() {
    return {
      // elmentList:
      //   [
      //     {
      //       pcImg: require("./vedio/1.mp4"),
      //       phoneImg: require("./vedio/V1_-phone.mp4"),
      //       descList: [
      //         {
      //           descName: '防爆化工四足机器人',
      //           sort: 1
      //         }, {
      //           descName: '让社会更智能',
      //           sort: 2
      //         }
      //       ],
      //       type: 'vedio',
      //       sort: 1
      //     },
      //     {
      //       pcImg: require("./vedio/2.mp4"),
      //       phoneImg: require("./vedio/V2_phone.mp4"),
      //       descList: [
      //         {
      //           descName: '防爆化工轮式巡检机器人高性能版',
      //           sort: 1
      //         }, {
      //           descName: '让社会更智能',
      //           sort: 2
      //         }
      //       ],
      //       type: 'vedio',
      //       sort: 2
      //     },
      //     {
      //       pcImg: require("./vedio/3.mp4"),
      //       phoneImg: require("./vedio/V3_phone.mp4"),
      //       descList: [
      //         {
      //           descName: '防爆化工挂轨巡检机器人',
      //           sort: 1
      //         }, {
      //           descName: '让社会更智能',
      //           sort: 2
      //         }
      //       ],
      //       type: 'vedio',
      //       sort: 3
      //     },
      //     {
      //       pcImg: require("./vedio/4.mp4"),
      //       phoneImg: require("./vedio/V4_phone_1.mp4"),
      //       descList: [
      //         {
      //           descName: '防爆消防灭火侦察机器人',
      //           sort: 1
      //         }, {
      //           descName: '让社会更智能',
      //           sort: 2
      //         }
      //       ],
      //       type: 'vedio',
      //       sort: 3
      //     },
      //   ],
      elmentList: [],
      aboutswiper: null,
      clientWidth: window.innerHeight,
      activeIndex: 0,
    }
  },
  created() {
    this.$store.dispatch("pageset/getbannerIndex", 0);
    // document.onmousemove = function (e) {
    //   e = e || window.event;
    //   if (e.pageX || e.pageY) {
    //     movex = e.pageX;
    //     movey = e.pageY
    //   }
    // }
    // 获取当前页面的配置信息
    this.$store.dispatch('pageset/getPageInfo', 0).then(() => {
      this.elmentList = this.$store.state.pageset.sourceData.elmentList;
      setTimeout(() => {
        this.aboutSwiper();
        // 如遇无法自动播的情况，手动获取播放
        if (!this.$store.state.pageset.pcshow) {
          let video = document.getElementById('video0');
          video.play();
        }
      }, 100);
    });
  },
  // 销毁滚轮事件
  destroyed() {
    // 销毁swiper
    this.aboutswiper.destroy();
    this.aboutswiper=null;
  },
  methods: {
    // 初始化轮播图
    aboutSwiper() {
      this.openFullScreen();
      this.aboutswiper = new Swiper('.mySwiper', {
        observer: true, //修改swiper自己或子元素时，自动初始化swiper默认为false
        observeParents: true, //修改swiper的s父元素时，自动初始化swiper
        slidesPerView: 'auto',// 解决高分辨率下无法全屏
        direction: "vertical",
        speed: 300,
        mousewheel: true,
        mousewheelControl: true,
        scrollbar: {
          el: '.swiper-scrollbar',
          draggable: true,
        },
        on: {
          transitionEnd: () => {
            this.activeIndex = this.aboutswiper.activeIndex;
            this.$store.dispatch("pageset/getbannerIndex", this.activeIndex);
            if (this.aboutswiper.isEnd) {
              // 到达最后一张，根据需要更新索引
              this.activeIndex = this.elmentList.length - 1;
            }
            if (this.activeIndex != 0) {
              document.documentElement.style.setProperty('--var-primary-color', '#171A20');
            } else {
              document.documentElement.style.setProperty('--var-primary-color', '#fff');
            }
            if (!this.$store.state.pageset.pcshow) {
              let video = document.getElementById('video' + this.activeIndex);
              video.play();
            }
          }
        },
        activeIndex: 0
      });
    },

    openFullScreen() {
      const loading = this.$loading({
        lock: true,
        text: '',
        spinner: 'el-icon-loading',
        background: 'rgba(0,0,0,0.1)'
      });
      setTimeout(() => {
        loading.close()
      }, 100);
    },

    retention() {
      // this.$router.push('/reservation');
      this.$router.push('/reservation');
    },

    isAppleDevice() {
      const ua = navigator.userAgent.toLowerCase();
      return /iphone|ipad|phone|Mac/i.test(ua);
    },
    openDetails() {
      switch (this.activeIndex) {
        case 0:
          this.$router.push('/quadrupedX3Stable?pageId=94');
          break;
        case 1:
          this.$router.push('/wheeledHigh?pageId=9');
          break;
        case 2:
          this.$router.push('/flameproofHanging?pageId=10');
          break;
        case 3:
          this.$router.push('/fire?pageId=13');
          break;
        default:
          break;
      }
    }
  }
}