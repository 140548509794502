import Vue from "vue";
import VueRouter from "vue-router";
import { Message } from "element-ui";
import store from "../store";
import { getToken } from "@/utils/auth";
import Home from "@/views/home/home.vue";
import HomeEN from "@/views/home/en/home_en.vue";
import commonLayout from "@/layout/commonPage/index.vue"; //普通页面模板
import dataSet from "@/layout/dataSet/index.vue"; //数据设置模板
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "proScoll",
    component: commonLayout,
    children: [
      {
        path: "/",
        name: "Home",
        component: Home,
      },
      {
        path: "/en",
        name: "Home",
        component: HomeEN,
      },
      {
        path: "/about",
        name: "about",
        component: () => import("@/views/about/about.vue"),
      },
      {
        path: "/en/about",
        name: "about",
        component: () => import("@/views/about/en/about_en.vue"),
      },
      // 雇佣服务
      {
        path: "/employmentPlan",
        component: () => import("@/views/employmentPlan/employmentPlan.vue"),
      },
      {
        path: "/en/employmentPlan",
        component: () =>
          import("@/views/employmentPlan/en/employmentPlan_en.vue"),
      },
      {
        path: "/news",
        name: "news",
        component: () => import("@/views/news/news.vue"),
      },
      {
        path: "/en/news",
        name: "news",
        component: () => import("@/views/news/news.vue"),
      },
      {
        path: "/joinus",
        name: "joinus",
        component: () => import("@/views/joinus/joinus.vue"),
      },
      {
        path: "/solution",
        component: () => import("@/views/solution/solution.vue"),
      },
      {
        path: "/en/solution",
        component: () => import("@/views/solution/en/solution_en.vue"),
      },
      // ptz解决方案
      {
        path: "/ptzSolution",
        component: () =>
          import("@/views/solution/solutionArea/PTZsolution/ptzSolution.vue"),
      },
      {
        path: "/en/ptzSolution",
        component: () =>
          import(
            "@/views/solution/solutionArea/PTZsolution/en/ptzSolution_en.vue"
          ),
      },
      {
        path: "/solutionArea",
        component: () =>
          import("@/views/solution/solutionArea/solutionArea.vue"),
      },
      {
        path: "/en/solutionArea",
        component: () =>
          import("@/views/solution/solutionArea/en/solutionArea_en.vue"),
      },
      {
        path: "/areaDetail",
        component: () =>
          import(
            "@/views/solution/solutionArea/solutionAreaDetail/solutionAreaDetail.vue"
          ),
      },
      {
        path: "/en/areaDetail",
        component: () =>
          import(
            "@/views/solution/solutionArea/solutionAreaDetail/en/solutionAreaDetail_en.vue"
          ),
      },
      {
        path: "/pdfView",
        name: "pdfView",
        component: () => import("@/views/home/components/pdfView.vue"),
      },
      {
        path: "/servicePDF",
        name: "servicePDF",
        component: () => import("@/views/service/pdfView.vue"),
      },
      // 四足机器人
      {
        path: "/quadruped",
        component: () => import("@/views/details/quadruped/quadruped.vue"),
      },
      {
        path: "/en/quadruped",
        name: "quadruped_en",
        component: () =>
          import("@/views/details/quadruped/en/quadruped_en.vue"),
      },
      // 防爆化工轮式巡检机器人
      {
        path: "/chemicalWheel",
        name: "chemicalWheel",
        component: () =>
          import("@/views/details/chemicalRobot/chemicalWheel.vue"),
      },
      {
        path: "/en/chemicalWheel",
        name: "chemicalWheel_en",
        component: () =>
          import("@/views/details/chemicalRobot/en/chemicalWheel_en.vue"),
        // component: () => import('@/views/details/chemicalRobotLightly/en/chemicalWheelLightly_en.vue'),
      },
      // 防爆化工轮式巡检机器人轻享系列
      {
        path: "/chemicalWheelLightly",
        name: "chemicalWheelLightly",
        component: () =>
          import(
            "@/views/details/chemicalRobotLightly/chemicalWheelLightly.vue"
          ),
      },
      {
        path: "/en/chemicalWheelLightly",
        name: "chemicalWheelLightly_en",
        component: () =>
          import(
            "@/views/details/chemicalRobotLightly/en/chemicalWheelLightly_en.vue"
          ),
      },
      // 防爆挂轨
      {
        path: "/flameproofHanging",
        component: () =>
          import("@/views/details/flameproofHanging/flameproofHanging.vue"),
      },
      {
        path: "/en/flameproofHanging",
        component: () =>
          import(
            "@/views/details/flameproofHanging/en/flameproofHanging_en.vue"
          ),
      },
      // 电力轮式
      {
        path: "/electricWheel",
        component: () =>
          import("@/views/details/electricWheel/electricWheel.vue"),
      },
      {
        path: "/en/electricWheel",
        component: () =>
          import("@/views/details/electricWheel/en/electricWheel_en.vue"),
      },
      // 电力挂轨巡检
      {
        path: "/hangingrail",
        component: () => import("@/views/details/hangingrail/hangingrail.vue"),
      },
      {
        path: "/en/hangingrail",
        component: () =>
          import("@/views/details/hangingrail/en/hangingrail_en.vue"),
      },
      // 防爆化工轮式巡检机器人高性能版
      {
        path: "/wheeledHigh",
        component: () => import("@/views/details/wheeledHigh/wheeledHigh.vue"),
      },

      {
        path: "/en/wheeledHigh",
        name: "wheeledHigh_en",
        component: () =>
          import("@/views/details/wheeledHigh/en/wheeledHigh_en.vue"),
      },
      // 防爆消防灭火侦察机器人
      {
        path: "/fire",
        component: () => import("@/views/details/fireRobot/fire.vue"),
      },
      {
        path: "/en/fire",
        component: () => import("@/views/details/fireRobot/en/fire_en.vue"),
      },
      // 防爆轮式机器人-SGLS-C3
      {
        path: "/SGLSC3",
        component: () => import("@/views/details/SGLSC3/SGLSC3.vue"),
      },
      {
        path: "/en/SGLSC3",
        component: () => import("@/views/details/SGLSC3/en/SGLSC3_en.vue"),
      },
      // 防爆轮式机器人-L4-S
      {
        path: "/wheeledHighL4S",
        component: () =>
          import("@/views/details/wheeledHighL4S/wheeledHighL4S.vue"),
      },
      {
        path: "/en/wheeledHighL4S",
        component: () =>
          import("@/views/details/wheeledHighL4S/en/wheeledHighL4S_en.vue"),
      },
      // 防爆轮式机器人-L7 Fire
      {
        path: "/wheeledHighL7fire",
        component: () =>
          import("@/views/details/wheeledHighL7fire/wheeledHighL7fire.vue"),
      },
      {
        path: "/en/wheeledHighL7fire",
        component: () =>
          import(
            "@/views/details/wheeledHighL7fire/en/wheeledHighL7fire_en.vue"
          ),
      },
      // 防爆履带机器人-D071
      {
        path: "/explosionProofTrackD071",
        component: () =>
          import(
            "@/views/details/explosionProofTrackD071/explosionProofTrackD071.vue"
          ),
      },
      {
        path: "/en/explosionProofTrackD071",
        component: () =>
          import(
            "@/views/details/explosionProofTrackD071/en/explosionProofTrackD071_en.vue"
          ),
      },
      // 防爆四足机器人-X3 Stable
      {
        path: "/quadrupedX3Stable",
        component: () =>
          import("@/views/details/quadrupedX3Stable/quadrupedX3Stable.vue"),
      },
      {
        path: "/en/quadrupedX3Stable",
        component: () =>
          import(
            "@/views/details/quadrupedX3Stable/en/quadrupedX3Stable_en.vue"
          ),
      },
      //=============周边=============
      //防爆充电桩
      {
        path: "/chargingStation",
        component: () =>
          import("@/views/around/chargingStation/chargingStation.vue"),
      },
      {
        path: "/en/chargingStation",
        component: () =>
          import("@/views/around/chargingStation/en/chargingStation_en.vue"),
      },
      //防爆充电房
      {
        path: "/chargingRoom",
        component: () => import("@/views/around/chargingRoom/chargingRoom.vue"),
      },
      {
        path: "/en/chargingRoom",
        component: () =>
          import("@/views/around/chargingRoom/en/chargingRoom_en.vue"),
      },
      //防爆云台
      {
        path: "/QMYT-Ex",
        component: () => import("@/views/around/QMYTEx/QMYTEx.vue"),
      },
      {
        path: "/en/QMYT-Ex",
        component: () => import("@/views/around/QMYTEx/en/QMYTEx_en.vue"),
      },
      //性能底盘
      {
        path: "/chassis",
        component: () => import("@/views/around/chassis/chassis.vue"),
      },
      {
        path: "/en/chassis",
        component: () => import("@/views/around/chassis/en/chassis_en.vue"),
      },
      //3D实时地图
      {
        path: "/threeDMap",
        component: () => import("@/views/around/threeDMap/threeDMap.vue"),
      },
      {
        path: "/en/threeDMap",
        component: () => import("@/views/around/threeDMap/en/threeDMap_en.vue"),
      },
      // 合作伙伴
      {
        path: "/partners",
        component: () => import("@/views/partners/partners.vue"),
      },
      {
        path: "/en/partners",
        component: () => import("@/views/partners/en/partners_en.vue"),
      },
      // 售后
      {
        path: "/service",
        component: () => import("@/views/service/service.vue"),
      },
      {
        path: "/en/service",
        component: () => import("@/views/service/en/service_en.vue"),
      },
      // 加盟七腾
      {
        path: "/joinSevnce",
        name: "joinSevnce",
        component: () => import("@/views/joinSevnce/joinSevnce.vue"),
      },
      {
        path: "/en/joinSevnce",
        name: "joinSevnce",
        component: () => import("@/views/joinSevnce/en/joinSevnce_en.vue"),
      },
      // 客户留资
      {
        path: "/reservation",
        name: "reservation",
        component: () => import("@/views/reservation/index.vue"),
      },
      {
        path: "/en/reservation",
        name: "reservation",
        component: () => import("@/views/reservation/en/index.vue"),
      },
      {
        path: "/login",
        name: "login",
        component: () => import("@/views/login/index.vue"),
      },
      {
        path: "/en/login",
        name: "login",
        component: () => import("@/views/login/en/index_en.vue"),
      },
      // 创建账户
      {
        path: "/register",
        name: "register",
        component: () => import("@/views/register/index.vue"),
      },
      {
        path: "/en/register",
        name: "register",
        component: () => import("@/views/register/en/index.vue"),
      },
    ],
  },
  // 新闻中心详情
  {
    path: "/newsDetail",
    name: "newsDetail",
    component: () => import("@/views/news/newsDetail/newsDetail.vue"),
  },
  {
    path: "/en/newsDetail",
    name: "newsDetail",
    component: () => import("@/views/news/newsDetail/newsDetail.vue"),
  },
  // 数据配置
  {
    path: "",
    name: "dataSet",
    component: dataSet,
    children: [
      {
        path: "/materialManagement",
        name: "materialManagement",
        component: () => import("@/views/dataSet/materialManagement/index.vue"),
      },
      {
        path: "/customManagment",
        name: "customManagment",
        component: () => import("@/views/dataSet/customManagment/index.vue"),
      },
      {
        path: "/menuSet",
        name: "menuSet",
        component: () => import("@/views/dataSet/menuSet/index.vue"),
      },
      {
        path: "/newsManegment",
        name: "newsManegment",
        component: () => import("@/views/dataSet/newsManegment/index.vue"),
      },
      {
        path: "/solutionManagment",
        name: "solutionManagment",
        component: () => import("@/views/dataSet/solutionManagment/index.vue"),
      },
    ],
  },
];

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

/*路由守卫*/
router.beforeEach((to, from, next) => {
  // 重置滚动条
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  // 获取用户信息
  const hasToken = getToken();
  if (hasToken) {
    // 如果是跳转登录,注册页，就跳转
    if (to.path === "/login" || to.path === "/register") {
      next();
    } else {
      store.dispatch("user/getInfo").then((res) => {
        // 数据设置页面，必须登录
        if (!to.matched.length) {
          next();
        } else if (to.matched[0].name === "dataSet") {
          if (!res) {
            Message.info("请先以管理员账号登录系统！");
            next(`/login`);
            return;
          } else {
            next();
          }
        } else {
          // 收起菜单
          store.dispatch("pageset/getMenuShow", false);
          next();
          return;
        }
      });
    }
  } else {
    // 收起菜单
    store.dispatch("pageset/getMenuShow", false);
    next();
  }
});
export default router;
